// ./src/components/Navigation.js

import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import { Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import { signIn, signOut } from '../AuthSpa';


const NavigationBar = styled.div`
  margin-bottom: 15px;
  background-color: #f8f8f8;
  padding: 10px;
`;

const Profile = styled.span`
  margin-left: 15px;
`;

const ProfilePicture = styled.img`
  max-width: 30px;
  margin-right: 5px;
`;

const Navigation = ({user}) => { 
  
  const navigate = useNavigate();
  //debugger;
  function handleHomeClick(){
    navigate('/');
  }
  function handleProfileClick(){
    navigate('/profile');
  }
  function handleDirectChargeClick(){
    navigate('/directcharge');
  }
  return(
    
    <NavigationBar>
      <Button className="btn btn-primary mr-2" onClick={handleHomeClick}>
        Home
      </Button>
      <Button className="btn btn-primary mr-2" onClick={handleProfileClick}>
        Profile
      </Button>
      <Button className="btn btn-primary mr-2" onClick={handleDirectChargeClick}>
        Direct Charge
      </Button>
        {!user && (
        <Button onClick={signIn}>Auth0 Login</Button>) }
        {user && (
          <Fragment>
            <Button onClick={signOut}>Logout</Button>
            <Profile>
              <ProfilePicture src={user["https://dogfy.com/random_dog"]} />
              {user.nickname} &nbsp;
              ({user.email})
            </Profile>
          </Fragment>
        )}
        {/*isAuthenticated && <Button onClick={signOut}>Logout</Button>*/}
    </NavigationBar>
  )
};

export default Navigation;
/*
export default ({user}) => { 
const navigate = useNavigate();
function handleMessageClick(){
  navigate('/');
}
function handleNewItemClick(){
  navigate('/new-item');
}
return(
  
  <NavigationBar>
    <Button className="btn btn-primary mr-2" onClick={handleMessageClick}>
      Home
    </Button>
    <Button className="btn btn-secondary mr-2 d-none" onClick={handleNewItemClick}>
      Add Message
    </Button>
      {!user && <Button onClick={signIn}>Auth0 Login</Button>}
      {user && (
        <Fragment>
          <Button onClick={signOut}>Logout</Button>
          <Profile>
            <ProfilePicture src={user.profile["https://dogfy.com/random_dog"]} />
            {user.profile.nickname} &nbsp;
            ({user.profile.email})
          </Profile>
        </Fragment>
      )}
  </NavigationBar>
)};*/