// ./src/App.js

import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Routes,Route } from 'react-router-dom';
import NavigationContainer from './containers/NavigationContainer';

import AppRoutes from "./_routes/app-routes";

class App extends Component {
  render() {
    return (
      <Container>
        <Row className="row">
          <Col xs={12}>
            <NavigationContainer />
            <h2>PTLSR Auth0 Test</h2>
            
            <Routes>
              {AppRoutes.map((route) => (
                <Route exact 
                  path={route.path}
                  element={route.component}
                  key={route.path}
                />
              ))}
            </Routes>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default App;