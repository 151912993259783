import React, { useEffect } from "react";
import {DirectCharge} from '../pages/directcharge';
import PropTypes from "prop-types";


function DirectChargeLayout({ children }) {
  useEffect(() => {
    document.title = "Direct Charge Layout";
  }, []);

  return (
  <div>{children}</div>
);
}

export default DirectChargeLayout;