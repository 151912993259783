import React, { useEffect } from 'react';
import {useSelector} from 'react-redux';
import { Fragment } from 'react';
import { fetchProtectedData, getToken, getToken2, handleLogin } from '../AuthSpa';

const Profile = () => {
    const user = useSelector(state => state.user);

    useEffect(() => {
        fetchProtectedData().then(data => {
            console.log(">> Profile: ",data);
        });
        document.title = 'Profile';
        exeJs();
    }, []);

    const exeJs = () => {
        console.log(">> Profile: Attach GsgPublicSite");

        const GsgPublicSite = window.GsgPublicSite.attach({
            site: "ca-sanbernardino/ca-sanbernardino",
            rootIframeUrl: "https://gsgbeta.sbcountyatc.gov",
            initialPath: "/my-dashboard",
            language: "en",
            googleAnalytics: {
                googleTagId: "",
                userId: "",
            },
            elementSelector: "#gsg-embedded-public-site",
            onPathChange: (newPath) => {
                // ...update your page's path with newPath
                document.getElementById('embedded-public-site-path').innerText = newPath;
            },
            getJwt: () => {
                // use 2nd Auth0 App
                return getToken2().then(token => {
                    const jwt = token;
                    console.log(">> Profile: Execute getJwt", jwt);
                    return jwt;
                });
            },
            handleLogin: () => {
                // ...redirect the user to your login page
                handleLogin();
            }
        });
    };

    return (
        <div>
        {!user && (
                <div>
                <h4>Embedded Test Page</h4>
                <p>You need to login ..</p>
                </div>
        )}
        {user && (
        <Fragment>
            <h4>Embedded Test Page</h4>
            <div id="gsg-embedded-public-site"></div>
        </Fragment>
    )}
        </div>        
    );
};

export default Profile;