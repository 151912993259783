import ProfileLayout from '../layout/profile-layout';
import Profile from '../pages/profile';
import DirectChargeLayout from '../layout/directcharge-layout';
import DirectCharge from '../pages/directcharge';
import Callback from '../containers/Callback.js';

const routes = [
    {
        path: '/callback', component: <Callback />
    },
    {
        path: '/profile', component: <ProfileLayout><Profile /></ProfileLayout>
    },
    {
        path: '/directcharge', component: <DirectChargeLayout><DirectCharge /></DirectChargeLayout>
    }
];

export default routes;