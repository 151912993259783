// ./src/_reducers/index.js


import { USER_PROFILE_LOADED } from '../_actions';
import { DIRECT_CHARGE_LOADED } from '../_actions';

const initialState = {
  msgList: []
};

export default function msgApp(state = initialState, action) {
  switch (action.type) {
    
    case USER_PROFILE_LOADED:
        return {
          ...state,
          user: action.user
        };
    case DIRECT_CHARGE_LOADED:
          return {
            ...state,
            user: action.user
          };
    default:
      return state;
  }
}