import React, { useEffect } from "react";
import {Profile} from '../pages/profile';
import PropTypes from "prop-types";


function ProfileLayout({ children }) {
  useEffect(() => {
    document.title = "Profile Layout";
  }, []);

  return (
  <div>{children}</div>
);
}

export default ProfileLayout;