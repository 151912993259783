// ./src/AuthSpa.js

import { Auth0Client } from '@auth0/auth0-spa-js';

const auth0Client = new Auth0Client({
    // the following three lines MUST be updated
    //domain: 'dev-atcsbcounty2.us.auth0.com',
    domain: 'authdev.sbcountyatc.gov',
    clientId: 'Lig9xO3j5eu2LJaVZDLaV2BcUNu8ZDvs',
    //useRefreshTokens: true,
    cacheLocation: 'memory',
    authorizationParams: {
        audience: 'Auth0PostLoginAPI',
        redirect_uri: 'https://atcwebtest.sbcountyatc.gov/callback',
        //redirect_uri: 'https://localhost:17505/callback',
        scope: 'openid profile email phone_number',
      },
    onRedirectCallback: (appState) => {
        if (appState && appState.returnTo) {
          window.history.replaceState(
            {},
            document.title,
            appState.returnTo
          );
        }
      }
});

const auth0Client2 = new Auth0Client({
    // the following three lines MUST be updated
    //domain: 'dev-atcsbcounty2.us.auth0.com',
    domain: 'authdev.sbcountyatc.gov',
    clientId: 'a1GUD6OW2VxObmqrzU22Y6VKm55MUJPr',
    //useRefreshTokens: true,
    cacheLocation: 'memory',
    authorizationParams: {
        audience: 'Auth0PostLoginAPI',
        redirect_uri: 'https://atcwebtest.sbcountyatc.gov/callback',
        //redirect_uri: 'https://localhost:17505/callback',
        scope: 'openid profile email',
      },
    onRedirectCallback: (appState) => {
        if (appState && appState.returnTo) {
          window.history.replaceState(
            {},
            document.title,
            appState.returnTo
          );
        }
      }

});

export function handleAuthentication() {
    return new Promise((resolve, reject) => {
        auth0Client.handleRedirectCallback().then(async (authResult) => {
            const user = await auth0Client.getUser();
            //debugger;
            resolve(user);
        }).catch(err => {
            reject(err);
        });
    });
}

export function signIn() {
    auth0Client.loginWithRedirect({
        appState: { 
            target: window.location.pathname,
            returnTo: window.location.pathname
        }
    });
}

export function signOut() {
    auth0Client.logout({
        //returnTo: 'https://atcwebtest.sbcountyatc.gov',
        returnTo: 'https://localhost:17505',
        client_id: 'Lig9xO3j5eu2LJaVZDLaV2BcUNu8ZDvs'
    });
}

const getToken = async () =>  {
    const accessToken = await auth0Client.getTokenSilently();
    return accessToken;
}
export { getToken };

// Auth0Client2
const getToken2 = async () =>  {
    const accessToken = await auth0Client2.getTokenSilently();
    return accessToken;
}
export { getToken2 };

const fetchProtectedData = async () => {
    const isAuthenticated = await auth0Client.isAuthenticated();
    if (isAuthenticated) {
        try {
            const accessToken = await auth0Client.getTokenSilently();
            
            console.log("++ AuthSpa Token: ", accessToken);
            // Use the access token to make an authenticated API call
            /*const response = await fetch('https://dev-atcsbcounty2.us.auth0.com/userinfo', {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });*/
            const response = await fetch('https://authdev.sbcountyatc.gov/userinfo', {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });
            const data = await response.json();
            //console.log("++ AuthSpa: ",data);
            return data;
        } catch (error) {
            console.error('Error getting token silently:', error);
            if (error.error === 'login_required') {
                // The user's session has expired, prompt them to log in again
                await auth0Client.loginWithRedirect();
            }
        }
    } else {
        // The user is not authenticated, prompt them to log in
        await auth0Client.loginWithRedirect();
    }
};

export { fetchProtectedData };

const handleLogin= async () => {
    await auth0Client.loginWithRedirect({
        redirect_uri: 'https://atcwebtest.sbcountyatc.gov/callback',
        //redirect_uri: 'https://localhost:17505/callback',
        audience: 'Auth0PostLoginAPI',
        scope: 'openid profile email',
        appState: { 
            target: window.location.pathname,
            returnTo: window.location.pathname
        }
    });
};

export { handleLogin };

const onRedirectCallback = (appState) => {
    if (appState && appState.returnTo) {
      window.history.replaceState(
        {},
        document.title,
        appState.returnTo
      );
    }
  };


