import React, { useEffect } from 'react';
import {useSelector} from 'react-redux';
import { Fragment } from 'react';
import { fetchProtectedData, getToken, getToken2, handleLogin } from '../AuthSpa';

const DirectCharge = () => {
    const user = useSelector(state => state.user);

    useEffect(() => {
        fetchProtectedData().then(data => {
            console.log(">> Direct Charge: ",data);
        });
        document.title = 'Direct Charge';
        exeJs();
    }, []);

    const exeJs = () => {
        console.log(">> Direct Charge: Attach GsgPublicSite");

        const DirectCharge = window.DirectCharge.attach({
            site: "ca-sanbernardino/direct-charge",
            elementSelector: "#gsg-direct-charge",
            environment: "beta",
            language: "en",
            getJwt: () => {
                // use 2nd Auth0 App
                return getToken2().then(token => {
                    const jwt = token;
                    console.log(">> Direct Charge: Execute getJwt", jwt);
                    return jwt;
                });
            },
            enableTestMode: false,
            initialPath: "/",

            onError: function (error) {
                alert(error.message)
            },
        });
    };

    return (
        <div>
        {!user && (
                <div>
                <h4>Direct Charge Test Page</h4>
                <p>You need to login ..</p>
                </div>
        )}
        {user && (
        <Fragment>
            <h4>Direct Charge Test Page</h4>
            <div id="gsg-direct-charge"></div>
        </Fragment>
    )}
        </div>        
    );
};

export default DirectCharge;