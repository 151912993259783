// ./src/_actions/index.js

export const USER_PROFILE_LOADED = 'USER_PROFILE_LOADED';
export const DIRECT_CHARGE_LOADED = 'DIRECT_CHARGE_LOADED';
export const HANDLE_AUTHENTICATION_CALLBACK = 'HANDLE_AUTHENTICATION_CALLBACK';



  export function handleAuthenticationCallback() {
    return {
      type: HANDLE_AUTHENTICATION_CALLBACK
    };
  }