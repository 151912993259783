// ./src/_sagas/index.js

import { all, call, put, takeEvery } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { HANDLE_AUTHENTICATION_CALLBACK, USER_PROFILE_LOADED, DIRECT_CHARGE_LOADED } from '../_actions';
import { handleAuthentication } from '../AuthSpa';


export function* parseHash() {
  //console.log(">> parseHash");
  const user = yield call(handleAuthentication);
  yield put({ type: USER_PROFILE_LOADED, user });
}

export function* handleAuthenticationCallback() {
  //console.log(">> handleAuthenticationCallback");
  yield takeLatest(HANDLE_AUTHENTICATION_CALLBACK, parseHash);
}

// replace the current rootSaga generator
export default function* rootSaga() {
  yield all([ handleAuthenticationCallback()]);
}