// ./src/containers/Callback.js

import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { handleAuthenticationCallback } from '../_actions';

const mapStateToProps = state => {
  return {
    user: state.user
  };
};
//debugger;
//console.log("++ At Callback");
let Callback = ({ dispatch, user }) => {
  if (user) {
    console.log("++ Callback: ",user);
    return <Navigate to="/" />
  };
  dispatch(handleAuthenticationCallback());

  return <div className="text-center">Loading user profile.</div>;
};
Callback = connect(mapStateToProps)(Callback);

export default Callback;